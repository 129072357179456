export const DrawingMode = {
  DrawPain: "draw_pain",
  Eraser: "eraser",
  PanView: "pan_view",
  PainLocation: "pain_location",
};

export const AnatomyCodes = {
  "bones-pos-cranium-occipitale": 31640002,
  "bones-pos-cranium-2": 89546000,
  "bones-pos-columna-C01": 14806007,
  "bones-pos-columna-C02": 39976000,
  "bones-pos-columna-C03": 113205007,
  "bones-pos-columna-C04": 5329002,
  "bones-pos-columna-C05": 36978003,
  "bones-pos-columna-C06": 36054005,
  "bones-pos-columna-C07": 87391001,
  "bones-pos-columna-T01": 64864005,
  "bones-pos-columna-T02": 53733008,
  "bones-pos-columna-T03": 1626008,
  "bones-pos-columna-T04": 73071006,
  "bones-pos-columna-T05": 56401006,
  "bones-pos-columna-T06": 45296009,
  "bones-pos-columna-T07": 62487009,
  "bones-pos-columna-T08": 11068009,
  "bones-pos-columna-T09": 82687006,
  "bones-pos-columna-T10": 7610001,
  "bones-pos-columna-T11": 12989004,
  "bones-pos-columna-T12": 23215003,
  "bones-pos-columna-L01": 66794005,
  "bones-pos-columna-L02": 14293000,
  "bones-pos-columna-L03": 36470004,
  "bones-pos-columna-L04": 11994002,
  "bones-pos-columna-L05": 49668003,
  "bones-pos-columna-sacrum": 54735007,
  "bones-pos-columna-coccyx": 64688005,
  "bones-pos-coxa-symphysis": 336205008,
  "bones-pos-coxa-dex": 28254004, // TODO: Find coding scheme for dex vs. sin
  "bones-pos-coxa-sin": 28254004,
  "bones-pos-superius-sin-scapula": -1,
  "bones-pos-superius-sin-manus": -1,
  "bones-pos-superius-sin-ulna": -1,
  "bones-pos-superius-sin-radius": -1,
  "bones-pos-superius-sin-humerus": -1,
  "bones-pos-superius-dex-scapula": -1,
  "bones-pos-superius-dex-manus": -1,
  "bones-pos-superius-dex-ulna": -1,
  "bones-pos-superius-dex-radius": -1,
  "bones-pos-superius-dex-humerus": -1,
  "bones-pos-thorax-costa-sin-01": -1,
  "bones-pos-thorax-costa-sin-02": -1,
  "bones-pos-thorax-costa-sin-03": -1,
  "bones-pos-thorax-costa-sin-04": -1,
  "bones-pos-thorax-costa-sin-05": -1,
  "bones-pos-thorax-costa-sin-06": -1,
  "bones-pos-thorax-costa-sin-07": -1,
  "bones-pos-thorax-costa-sin-08": -1,
  "bones-pos-thorax-costa-sin-09": -1,
  "bones-pos-thorax-costa-sin-10": -1,
  "bones-pos-thorax-costa-sin-11": -1,
  "bones-pos-thorax-costa-sin-12": -1,
  "bones-pos-thorax-costa-pos-01": -1,
  "bones-pos-thorax-costa-pos-02": -1,
  "bones-pos-thorax-costa-pos-03": -1,
  "bones-pos-thorax-costa-pos-04": -1,
  "bones-pos-thorax-costa-pos-05": -1,
  "bones-pos-thorax-costa-pos-06": -1,
  "bones-pos-thorax-costa-pos-07": -1,
  "bones-pos-thorax-costa-pos-08": -1,
  "bones-pos-thorax-costa-pos-09": -1,
  "bones-pos-thorax-costa-pos-10": -1,
  "bones-pos-thorax-costa-pos-11": -1,
  "bones-pos-thorax-costa-pos-12": -1,
  "bones-pos-clavicula-dex": -1,
  "bones-pos-clavicula-sin": -1,
  "bones-pos-inferius-sin-femur": -1,
  "bones-pos-inferius-sin-tibia": -1,
  "bones-pos-inferius-sin-fibula": -1,
  "bones-pos-inferius-sin-pedis": -1,
  "bones-pos-inferius-dex-femur": -1,
  "bones-pos-inferius-dex-tibia": -1,
  "bones-pos-inferius-dex-fibula": -1,
  "bones-pos-inferius-dex-pedis": -1,
  "bones-pos-superius-sin-scapula": -1,
  "bones-pos-superius-sin-humerus": -1,
  "bones-pos-superius-sin-ulna": -1,
  "bones-pos-superius-sin-radius": -1,
  "bones-pos-superius-sin-manus": -1,
  "bones-pos-superius-dex-scapula": -1,
  "bones-pos-superius-dex-humerus": -1,
  "bones-pos-superius-dex-ulna": -1,
  "bones-pos-superius-dex-radius": -1,
  "bones-pos-superius-dex-manus": -1,
};

export const SnomedCTCodes = {
  31640002: "Occipital bone structure (body structure)",
  89546000: "Bone structure of cranium (body structure)",
  14806007: "Bone structure of C1 (body structure)",
  39976000: "Bone structure of C2 (body structure)",
  113205007: "Bone structure of C3 (body structure)",
  5329002: "Bone structure of C4 (body structure)",
  36978003: "Bone structure of C5 (body structure)",
  36054005: "Bone structure of C6 (body structure)",
  87391001: "Bone structure of C7 (body structure)",
  64864005: "Bone structure of T1 (body structure)",
  53733008: "Bone structure of T2 (body structure)",
  1626008: "Bone structure of T3 (body structure)",
  73071006: "Bone structure of T4 (body structure)",
  56401006: "Bone structure of T5 (body structure)",
  45296009: "Bone structure of T6 (body structure)",
  62487009: "Bone structure of T7 (body structure)",
  11068009: "Bone structure of T8 (body structure)",
  82687006: "Bone structure of T9 (body structure)",
  7610001: "Bone structure of T10 (body structure)",
  12989004: "Bone structure of T11 (body structure)",
  23215003: "Bone structure of T12 (body structure)",
  66794005: "Bone structure of L1 (body structure)",
  14293000: "Bone structure of L2 (body structure)",
  36470004: "Bone structure of L3 (body structure)",
  11994002: "Bone structure of L4 (body structure)",
  49668003: "Bone structure of L5 (body structure)",
  54735007: "Bone structure of sacrum (body structure)",
  64688005: "Bone structure of coccyx (body structure)",
  336205008: "Symphysis structure (body structure)",
  28254004: "Innominate bone structure (body structure)",

  279029001: "Pain in cervical spine (finding)",
  267981009: "Pain in thoracic spine (finding)",
  267982002: "Pain in lumbar spine (finding)",
};
