import { SVG } from "@svgdotjs/svg.js";
import imagePath from "../../assets/images/body_front_back.svg";
import {
  DrawingMode,
  AnatomyCodes,
  SnomedCTCodes,
} from "../constants/Constants";
import { Marker } from "./Marker";
import { exportToPDF } from "../utils/PDFExporter";
import { createPainObservationBundle } from "../utils/FHIRExporter";

export class DrawingApp {
  constructor() {
    this.draw = SVG().addTo("#PD").size("100%", "100%");
    this.containerWidth = 400;
    this.containerHeight = 800;
    this.draw.viewbox(0, 0, this.containerWidth, this.containerHeight);
    this.ctx = null;
    this.image = null;
    this.isDrawing = false;
    this.activePath = null;
    this.points = [];
    this.pathsGroup = null;
    this.paths = [];
    this.pathsData = [];
    this.pointCaptureThreshold = 0.00001; // smaller value means more frequent point capture
    this.strokeWidth = 10;
    this.strokeConfig = { width: this.strokeWidth, color: "#FF6B6B" };
    this.eraserRadius = this.strokeWidth;
    this.isPanning = false;
    this.panStartX = 0;
    this.panStartY = 0;
    this.panOffsetX = 0;
    this.panOffsetY = 0;
    this._zoomLevel = 1;
    this.pinchStartDistance = null;
    this.pinchMidpointX = 0;
    this.pinchMidpointY = 0;
    this.activeDrawingMode = DrawingMode.PanView;
    this.painMarker = null;
  }

  initialize() {
    this.loadImage().then(() => {
      this.setupEventListeners();
    });
  }

  get zoomLevel() {
    return this._zoomLevel;
  }

  set zoomLevel(value) {
    const minZoomLevel = 0.2;
    const maxZoomLevel = 3;
    this._zoomLevel = Math.min(Math.max(value, minZoomLevel), maxZoomLevel);

    // TODO: Implement zooming around the pinching point
  }

  loadImage() {
    return new Promise((resolve, reject) => {
      fetch(imagePath)
        .then((response) => response.text())
        .then((svgData) => {
          this.draw.svg(svgData);
          this.image = this.draw.last();

          // Remove the viewBox attribute from the loaded SVG sub-element
          if (this.image.node.tagName === "svg") {
            this.image.attr("viewBox", null);
          }

          const svgWidth = 1200;
          const svgHeight = svgWidth * 1.144485807352257;
          this.image.size(svgWidth, svgHeight);
          const centerX = this.containerWidth / 2 - svgWidth / 2;
          const centerY = this.containerHeight / 2 - svgHeight / 2;
          this.image.move(centerX, centerY);
          this.image.front();

          // Create the pathsGroup to be in front of the image
          this.pathsGroup = this.draw.group().opacity(0.7); // Set group opacity
          this.pathsGroup.front(); // Ensure pathsGroup is in front of the image

          resolve();
        })
        .catch((error) => {
          console.error("Error loading SVG image:", error);
          reject(error);
        });
    });
  }

  setupEventListeners() {
    this.draw.on("mousedown", this.handleMouseDown.bind(this));
    this.draw.on("touchstart", this.handleTouchStart.bind(this));
    this.draw.on("mousemove", this.handleMouseMove.bind(this));
    this.draw.on("touchmove", this.handleTouchMove.bind(this));
    this.draw.on("mouseup", this.handleMouseUp.bind(this));
    this.draw.on("touchend", this.handleTouchEnd.bind(this));
    document
      .getElementById("save")
      .addEventListener("click", this.handleSave.bind(this));
    document
      .getElementById("draw_pain")
      .addEventListener("click", () =>
        this.toggleActiveDrawingMode(DrawingMode.DrawPain)
      );
    document
      .getElementById("eraser")
      .addEventListener("click", () =>
        this.toggleActiveDrawingMode(DrawingMode.Eraser)
      );
    // document
    //   .getElementById("undo")
    //   .addEventListener("click", () => this.undoLastPath());
    document
      .getElementById("pain_location")
      .addEventListener("click", () =>
        this.toggleActiveDrawingMode(DrawingMode.PainLocation)
      );
    document
      .getElementById("reset")
      .addEventListener("click", this.resetDrawing.bind(this));
    document.addEventListener("contextmenu", (event) => event.preventDefault());
    const zoomSlider = document.getElementById("zoom-slider");
    zoomSlider.addEventListener(
      "input",
      this.handleZoomSliderChange.bind(this)
    );
    // Set the initial zoom level
    this.handleZoomSliderChange();
  }

  handleMouseDown(event) {
    if (event.button !== 0) {
      return;
    }
    this.startDraw(event.clientX, event.clientY);
  }

  handleTouchStart(event) {
    event.preventDefault();

    // Pinch to zoom
    if (event.touches.length === 2) {
      const dx = event.touches[0].pageX - event.touches[1].pageX;
      const dy = event.touches[0].pageY - event.touches[1].pageY;
      this.pinchStartDistance = Math.sqrt(dx * dx + dy * dy);
    }

    // Single touch to draw and pan
    if (event.touches.length > 1) {
      return;
    }
    this.startDraw(event.touches[0].clientX, event.touches[0].clientY);
  }

  handleMouseMove(event) {
    if (this.activeDrawingMode === DrawingMode.Eraser) {
      this.erasePath(event);
    } else if (this.activeDrawingMode === DrawingMode.DrawPain) {
      this.updatePath(event);
    } else if (
      this.activeDrawingMode === DrawingMode.PanView &&
      this.isPanning
    ) {
      this.panViewUpdate(event);
    }
  }

  handleTouchMove(event) {
    event.preventDefault();

    if (event.touches.length === 2 && this.pinchStartDistance !== null) {
      // Handle pinch to zoom
      const touch1X = event.touches[0].clientX;
      const touch1Y = event.touches[0].clientY;
      const touch2X = event.touches[1].clientX;
      const touch2Y = event.touches[1].clientY;
      this.pinchMidpointX = (touch1X + touch2X) / 2;
      this.pinchMidpointY = (touch1Y + touch2Y) / 2;
      const dx = touch1X - touch2X;
      const dy = touch1Y - touch2Y;
      const pinchCurrentDistance = Math.sqrt(dx * dx + dy * dy);
      this.zoomLevel *= pinchCurrentDistance / this.pinchStartDistance;
      this.pinchStartDistance = pinchCurrentDistance;
      this.handleZoomChange();
    } else if (this.activeDrawingMode === DrawingMode.Eraser) {
      this.erasePath(event.touches[0]);
    } else if (this.activeDrawingMode === DrawingMode.DrawPain) {
      this.updatePath(event.touches[0]);
    } else if (
      this.activeDrawingMode === DrawingMode.PanView &&
      this.isPanning
    ) {
      this.panViewUpdate(event.touches[0]);
    }
  }

  handleMouseUp() {
    this.stopDrawing();
  }

  handleTouchEnd(event) {
    // Handle pinch to zoom
    if (event.touches.length < 2) {
      this.pinchStartDistance = null;
    }

    this.stopDrawing();
  }

  handleZoomChange() {
    // Calculate the new viewbox dimensions
    const originalViewBoxWidth = this.containerWidth;
    const originalViewBoxHeight = this.containerHeight;
    const newViewBoxWidth = originalViewBoxWidth / this.zoomLevel;
    const newViewBoxHeight = originalViewBoxHeight / this.zoomLevel;

    // Calculate new center points
    const currentViewbox = this.draw.viewbox();
    const currentCenterX = currentViewbox.x + currentViewbox.width / 2;
    const currentCenterY = currentViewbox.y + currentViewbox.height / 2;

    // Set new offset
    const offsetX = currentCenterX - newViewBoxWidth / 2;
    const offsetY = currentCenterY - newViewBoxHeight / 2;

    // Update the viewbox with new dimensions
    this.draw.viewbox(offsetX, offsetY, newViewBoxWidth, newViewBoxHeight);
  }

  handleZoomSliderChange() {
    const zoomSlider = document.getElementById("zoom-slider");
    this.zoomLevel = zoomSlider.value;
    this.handleZoomChange();
  }

  startDraw(x, y) {
    const point = this.draw.point(x, y);
    this.isDrawing = true;
    switch (this.activeDrawingMode) {
      case DrawingMode.DrawPain:
        this.strokeConfig = { width: this.strokeWidth, color: "#DC143C" };
        break;
      case DrawingMode.Eraser:
        // nothing to do here
        break;
      case DrawingMode.PanView:
        this.isPanning = true;
        this.panStartX = x;
        this.panStartY = y;
        break;
      case DrawingMode.PainLocation:
        if (this.painMarker) {
          this.painMarker.move(point.x, point.y);
        } else {
          this.painMarker = new Marker(this.draw, { x: point.x, y: point.y });
          this.painMarker.setup();
        }
        break;
      default:
        this.strokeConfig = { width: this.strokeWidth, color: "#FF6B6B" };
        break;
    }
    if (
      this.activeDrawingMode !== DrawingMode.Eraser &&
      this.activeDrawingMode !== DrawingMode.PanView
    ) {
      this.points.push([point.x, point.y]);
      this.activePath = this.pathsGroup.path(`M ${point.x} ${point.y}`);
      this.activePath.fill("none").stroke(this.strokeConfig);
      this.paths.push(this.activePath);
      this.pathsData.push([]);
    }
  }

  // FIXME: Unused since Cors headers must be set on the server
  canDrawAt(x, y) {
    const pixel = this.ctx.getImageData(x, y, 1, 1).data;
    return !(pixel[3] === 0); // Check pixel transparency
  }

  updatePath(event) {
    if (this.isDrawing) {
      const point = this.draw.point(event.clientX, event.clientY);
      if (this.points.length > 0) {
        const lastPoint = this.points[this.points.length - 1];
        const distance = Math.sqrt(
          Math.pow(lastPoint[0] - point.x, 2) +
            Math.pow(lastPoint[1] - point.y, 2)
        );
        if (distance > this.pointCaptureThreshold) {
          this.recordPoint(point);
        }
      } else {
        // Always record the first point
        this.recordPoint(point);
      }
    }
  }

  recordPoint(point) {
    this.points.push([point.x, point.y]);
    let d = `M ${this.points[0][0]} ${this.points[0][1]}`;
    for (let i = 1; i < this.points.length; ++i) {
      d += ` L ${this.points[i][0]} ${this.points[i][1]}`;
    }
    this.activePath.plot(d);
    this.pathsData[this.pathsData.length - 1] = this.points;
  }

  erasePath(event) {
    if (this.isDrawing) {
      const point = this.draw.point(event.clientX, event.clientY);

      // Temporary arrays to store new path data during erasing
      let newPaths = [];
      let newPathsData = [];

      this.paths.forEach((path, index) => {
        const pathData = this.pathsData[index];
        let newPathData = [];
        let lastPointWasOutsideEraser = true;

        // Rebuild path data excluding segments within eraser radius
        pathData.forEach((currentPoint, i) => {
          const distance = Math.sqrt(
            Math.pow(currentPoint[0] - point.x, 2) +
              Math.pow(currentPoint[1] - point.y, 2)
          );
          if (distance > this.eraserRadius) {
            if (!lastPointWasOutsideEraser && newPathData.length) {
              // If coming from an erased segment, start a new path segment
              newPathsData.push(newPathData);
              newPathData = [];
            }
            newPathData.push(currentPoint);
            lastPointWasOutsideEraser = true;
          } else {
            lastPointWasOutsideEraser = false;
          }
        });

        // Add the last segment if it wasn't erased
        if (newPathData.length > 0) {
          newPathsData.push(newPathData);
        }

        // Remove the original path
        path.remove();
      });

      // Clear existing paths and recreate from remaining path data
      this.paths = [];
      this.pathsData = [];

      newPathsData.forEach((pathData) => {
        if (pathData.length > 1) {
          let newPath = this.pathsGroup.path(
            `M ${pathData[0][0]} ${pathData[0][1]}`
          );
          let d = `M ${pathData[0][0]} ${pathData[0][1]}`;
          pathData.forEach((point, index) => {
            if (index > 0) {
              d += ` L ${point[0]} ${point[1]}`;
            }
          });
          newPath.plot(d).fill("none").stroke(this.strokeConfig);
          this.paths.push(newPath);
          this.pathsData.push(pathData);
        }
      });
    }
  }

  panViewUpdate(event) {
    const zoomInverse = 1 / this.zoomLevel;
    const deltaX = (event.clientX - this.panStartX) * zoomInverse;
    const deltaY = (event.clientY - this.panStartY) * zoomInverse;
    this.panOffsetX = this.draw.viewbox().x - deltaX;
    this.panOffsetY = this.draw.viewbox().y - deltaY;
    this.draw.viewbox(
      this.panOffsetX,
      this.panOffsetY,
      this.draw.viewbox().width,
      this.draw.viewbox().height
    );
    this.panStartX = event.clientX;
    this.panStartY = event.clientY;
  }

  stopDrawing() {
    this.isDrawing = false;
    this.points = [];
    if (this.activeDrawingMode === DrawingMode.PanView) {
      this.isPanning = false;
    }
  }

  resetDrawing() {
    const isConfirmed = window.confirm(
      "Are you sure you want to reset the drawing?"
    );
    if (isConfirmed) {
      this.paths.forEach((path) => path.remove());
      this.paths = [];
      this.pathsData = [];
      if (this.painMarker) {
        this.painMarker.reset();
        this.painMarker = null;
      }
    }
  }

  toggleActiveDrawingMode(mode, defaultMode = DrawingMode.PanView) {
    if (mode === this.activeDrawingMode) {
      this.setActiveDrawingMode(defaultMode);
    } else {
      this.setActiveDrawingMode(mode);
    }
  }

  setActiveDrawingMode(mode) {
    this.activeDrawingMode = mode;
    const drawingModes = Object.values(DrawingMode);
    drawingModes.forEach((mode) => {
      document.getElementById(mode)?.classList.remove("active-button");
    });
    document.getElementById(mode)?.classList.add("active-button");
  }

  handleSave() {
    const painLocations = this.getPainLocations();

    let painMarkerPosition = null;
    if (this.painMarker) {
      painMarkerPosition = this.painMarker.getMarkerPosition();
    }
    const pdfData = exportToPDF(
      this.image,
      this.paths,
      this.pathsData,
      painMarkerPosition,
      this.containerWidth,
      this.containerHeight
    );

    // TODO: Extract pain locations and fill in the FHIR bundle
    // const pdfDataSample = "data:application/pdf;base64, sample data";
    const svgData = this.getPathsSVG();
    const patientId = "1";

    const fhirBundle = createPainObservationBundle(
      patientId,
      painLocations.primary,
      painLocations.additional,
      svgData
    );
    console.log(JSON.stringify(fhirBundle, null, 2));
  }

  getPathsSVG() {
    // Create a new SVG element to hold the paths
    const svg = SVG().size(this.containerWidth, this.containerHeight);

    // Clone pathsGroup and add to the new SVG element
    const clonedPathsGroup = this.pathsGroup.clone();
    svg.add(clonedPathsGroup);

    // Serialize the new SVG element to a string
    return svg.svg();
  }

  getPainLocations() {
    let primaryPainLocations = {};
    let painLocations = {};
    for (const [label, value] of Object.entries(AnatomyCodes)) {
      const isMarkerOverlap = this.checkMarkerOverlapWithElement(label);
      if (isMarkerOverlap) {
        primaryPainLocations[value] = SnomedCTCodes[value];
      }

      const isOverlap = this.checkPathOverlapWithElement(label);
      console.log(`Path overlap with ${label}: ${isOverlap}`);
      if (isOverlap) {
        painLocations[value] = SnomedCTCodes[value];
      }
    }
    return {
      primary: primaryPainLocations,
      additional: painLocations,
    };
  }

  /**
   * Checks if any path overlaps with a specified SVG element by ID.
   * @param {string} targetId The ID of the SVG element to check against.
   * @returns {boolean} True if there is an overlap; otherwise, false.
   */
  checkPathOverlapWithElement(targetId, drawBoundingBox = false) {
    const targetElement = this.draw.findOne(`#${targetId}`);
    if (!targetElement) {
      console.warn("Target element not found");
      return false;
    }

    const targetBBox = targetElement.rbox(this.draw); // Get the bounding box relative to the drawing context
    if (drawBoundingBox) {
      this.drawBoundingBox(targetElement, "blue");
    }

    // Check each path for overlap with the target element
    for (const path of this.paths) {
      const pathBBox = path.bbox(this.draw);
      if (drawBoundingBox) {
        this.drawBoundingBox(path, "blue");
      }
      if (this.boundingBoxesIntersect(pathBBox, targetBBox)) {
        return true;
      }
    }

    return false;
  }

  checkMarkerOverlapWithElement(targetId) {
    if (this.painMarker === null) {
      return false;
    }

    const targetElement = this.draw.findOne(`#${targetId}`);
    if (!targetElement) {
      console.warn("Target element not found");
      return false;
    }

    const markerBBox = this.painMarker.getMarkerBoundingBox();
    const targetBBox = targetElement.rbox(this.draw);

    if (this.boundingBoxesIntersect(markerBBox, targetBBox)) {
      return true;
    }

    return false;
  }

  drawBoundingBox(element, color = "red") {
    const bbox = element.bbox(this.draw); // Get the bounding box relative to the drawing context
    const rect = this.draw
      .rect(bbox.width, bbox.height)
      .move(bbox.x, bbox.y)
      .fill("none")
      .stroke({ color: color, width: 2 })
      .addClass("bounding-box"); // Class to identify bounding box rectangles

    this.draw.add(rect); // Add rectangle to the SVG drawing
  }

  /**
   * Determines if two bounding boxes intersect.
   * @param {object} bbox1 The first bounding box.
   * @param {object} bbox2 The second bounding box.
   * @returns {boolean} True if the bounding boxes intersect; otherwise, false.
   */
  boundingBoxesIntersect(bbox1, bbox2) {
    return (
      bbox1.x < bbox2.x2 &&
      bbox1.x2 > bbox2.x &&
      bbox1.y < bbox2.y2 &&
      bbox1.y2 > bbox2.y
    );
  }
}
