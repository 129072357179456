import jsPDF from "jspdf";

export async function exportToPDF(
  backgroundSVG,
  paths,
  pathsData,
  markerPosition,
  containerWidth,
  containerHeight
) {
  // Define A4 dimensions in pixels at 300 DPI
  const dpi = 300;
  const mmToInches = 1 / 25.4;
  const a4WidthPixels = Math.round(210 * mmToInches * dpi);
  const a4HeightPixels = Math.round(297 * mmToInches * dpi);

  // Create a canvas with A4 dimensions
  const canvas = document.createElement("canvas");
  canvas.width = a4WidthPixels;
  canvas.height = a4HeightPixels;
  const ctx = canvas.getContext("2d");

  // Define header and footer height in pixels
  const headerHeightPixels = 30 * mmToInches * dpi; // adjust as needed
  const footerHeightPixels = 30 * mmToInches * dpi; // adjust as needed

  // Serialize SVG to Data URL
  const serializer = new XMLSerializer();
  let source = serializer.serializeToString(backgroundSVG.node);
  source = '<?xml version="1.0" standalone="no"?>\r\n' + source;
  const blob = new Blob([source], { type: "image/svg+xml;charset=utf-8" });
  const url = URL.createObjectURL(blob);

  const image = new Image();
  image.src = url;
  await new Promise((resolve, reject) => {
    image.onload = resolve;
    image.onerror = () => {
      URL.revokeObjectURL(url); // Clean up URL after loading
      reject();
    };
  });

  // Calculate the scale to fit the drawing into A4 size, preserving the aspect ratio
  const scale = Math.min(
    a4WidthPixels / image.width,
    (a4HeightPixels - headerHeightPixels - footerHeightPixels) / image.height
  );

  // Calculate centered position for the drawing on the A4 page
  const scaledImageWidth = image.width * scale;
  const scaledImageHeight = image.height * scale;
  const offsetX = (a4WidthPixels - scaledImageWidth) / 2;
  const offsetY =
    (a4HeightPixels -
      scaledImageHeight -
      headerHeightPixels -
      footerHeightPixels) /
      2 +
    headerHeightPixels;

  // Draw the background image, scaled and centered
  ctx.drawImage(image, offsetX, offsetY, scaledImageWidth, scaledImageHeight);

  // Calculate the offset of the image within the container
  const imageOffsetX = (containerWidth - image.width) / 2;
  const imageOffsetY = (containerHeight - image.height) / 2;

  // Set up path grouping with semi-transparent context
  ctx.globalAlpha = 0.7; // Adjust for semi-transparency relative to the background

  // Iterate over paths to draw them scaled and centered
  paths.forEach((path, index) => {
    const pathData = pathsData[index];
    if (pathData) {
      ctx.beginPath();
      pathData.forEach((point, i) => {
        // Subtract the image offset from the path coordinates before scaling and offsetting them
        const [x, y] = [
          (point[0] - imageOffsetX) * scale + offsetX,
          (point[1] - imageOffsetY) * scale + offsetY,
        ];
        if (i === 0) ctx.moveTo(x, y);
        else ctx.lineTo(x, y);
      });
      ctx.strokeStyle = path.attr("stroke");
      ctx.lineWidth = path.attr("stroke-width") * scale;
      ctx.stroke();
    }
  });

  ctx.globalAlpha = 1.0; // Reset alpha to fully opaque for any subsequent drawing

  if (markerPosition) {
    drawMarker(
      ctx,
      markerPosition,
      imageOffsetX,
      imageOffsetY,
      scale,
      offsetX,
      offsetY
    );
  }

  // Create a jsPDF instance with A4 dimension
  const pdf = new jsPDF({
    orientation: "portrait",
    unit: "mm",
    format: "a4",
  });

  // Add header
  // Set font size for the title and add title
  pdf.setFontSize(14);
  pdf.setLineWidth(0.1);
  pdf.line(10, 4, 200, 4);
  pdf.text("Pain Drawing", 105, 10, { align: "center" });
  pdf.setLineWidth(0.1);
  pdf.line(10, 13, 200, 13);
  // Set font size for the form fields
  pdf.setFontSize(10);

  // Add form fields with adjusted positions for alignment
  pdf.text("Name: _____________________", 10, 20);
  pdf.text("Date of Birth: ____/____/______", 70, 20);
  pdf.text("Date: ____/____/______", 160, 20);

  // Add a line to separate the header from the rest of the content
  pdf.setLineWidth(0.1);
  pdf.line(10, 25, 200, 25);

  // Add footer
  // Add a line to separate the footer from the rest of the content
  pdf.setLineWidth(0.1);
  pdf.line(10, 285, 200, 285);

  // Set font size for the footer
  pdf.setFontSize(10);

  // Add footer text
  pdf.text("Page 1 of 1", 105, 292, { align: "center" });

  // Convert canvas to image and add to PDF
  pdf.addImage(canvas.toDataURL("image/png"), "PNG", 0, 0, 210, 297);

  // Download the PDF
  pdf.save("drawing_a4.pdf");

  return pdf.output("datauristring");
}

function drawMarker(
  ctx,
  markerPosition,
  imageOffsetX,
  imageOffsetY,
  scale,
  offsetX,
  offsetY
) {
  const crossRadius = 20;
  const crossOutlineMultiplier = 1.1;
  const crossX = (markerPosition.x - imageOffsetX) * scale + offsetX;
  const crossY = (markerPosition.y - imageOffsetY) * scale + offsetY;

  // Draw the outline
  ctx.beginPath();
  ctx.moveTo(crossX, crossY - crossRadius * crossOutlineMultiplier); // Top of the vertical line
  ctx.lineTo(crossX, crossY + crossRadius * crossOutlineMultiplier); // Bottom of the vertical line
  ctx.moveTo(crossX - crossRadius * crossOutlineMultiplier, crossY); // Left of the horizontal line
  ctx.lineTo(crossX + crossRadius * crossOutlineMultiplier, crossY); // Right of the horizontal line

  ctx.strokeStyle = "black"; // Color of the outline
  ctx.lineWidth = 14; // Width of the outline
  ctx.stroke(); // Render the outline

  // Draw the main cross
  ctx.beginPath();
  ctx.moveTo(crossX, crossY - crossRadius); // Top of the vertical line
  ctx.lineTo(crossX, crossY + crossRadius); // Bottom of the vertical line
  ctx.moveTo(crossX - crossRadius, crossY); // Left of the horizontal line
  ctx.lineTo(crossX + crossRadius, crossY); // Right of the horizontal line

  ctx.strokeStyle = "#d23f31"; // Color of the cross
  ctx.lineWidth = 10; // Width of the cross
  ctx.stroke(); // Render the cross
}
