export function createPainObservationBundle(
  patientId,
  primaryPainLocations,
  additionalPainLocations,
  svgContent
) {
  // Helper function to create an Observation resource
  function createObservation(
    code,
    display,
    patientId,
    dateTime,
    primary = false
  ) {
    let coding = [];
    if (primary) {
      coding.push({
        system: "http://loinc.org",
        code: "38204-4",
        display: "Pain Primary Location",
      });
    } else {
      coding.push({
        system: "http://loinc.org",
        code: "39111-0",
        display: "Body site",
      });
    }

    // FIXME: The id should be a UUID
    return {
      resourceType: "Observation",
      id: Math.random().toString(36).substr(2, 9),
      status: "final",
      text: {
        status: "generated",
        div: `<div xmlns="http://www.w3.org/1999/xhtml"><p>Pain location observation for Patient ${patientId}.</p></div>`,
      },
      code: {
        coding: coding,
      },
      performer: [{ reference: `Patient/${patientId}`, display: "Patient" }],
      valueCodeableConcept: {
        coding: [
          {
            system: "http://snomed.info/sct",
            code: code,
            display: display,
          },
        ],
      },
      subject: {
        reference: `Patient/${patientId}`,
      },
      effectiveDateTime: dateTime,
    };
  }

  // Current date and time
  const now = new Date().toISOString();

  // Convert svgContent to base64
  const base64SvgContent = Buffer.from(svgContent).toString("base64");
  const presentedForm = [
    {
      contentType: "image/svg+xml;charset=utf-8",
      language: "en",
      data: base64SvgContent,
      title: "Pain Drawing SVG",
    },
  ];

  // Create an array of primary pain location Observations
  const primaryPainObservations = Object.entries(primaryPainLocations).map(
    ([code, display]) => createObservation(code, display, patientId, now, true)
  );

  const additionalPainObservations = Object.entries(
    additionalPainLocations
  ).map(([code, display]) =>
    createObservation(code, display, patientId, now, false)
  );

  // Create an array of references to the primary and additional pain location Observations
  const observationReferences = [
    ...primaryPainObservations,
    ...additionalPainObservations,
  ].map((observation) => ({
    reference: `Observation/${observation.id}`,
  }));

  // Create the DiagnosticReport resource
  // TODO: Add the pain drawing image/pdf to the DiagnosticReport as media
  const diagnosticReport = {
    resourceType: "DiagnosticReport",
    id: Math.random().toString(36).substr(2, 9), // FIXME: The id should be a UUID
    status: "final",
    text: {
      status: "generated",
      div: `<div xmlns="http://www.w3.org/1999/xhtml"><p>Pain assessment panel report for Patient ${patientId}.</p></div>`,
    },
    code: {
      coding: [
        {
          system: "http://loinc.org",
          code: "38212-7",
          display: "Pain assessment panel",
        },
      ],
    },
    subject: {
      reference: `Patient/${patientId}`,
    },
    effectiveDateTime: now,
    result: observationReferences,
    presentedForm: presentedForm,
  };

  // Create the main Bundle
  const bundle = {
    resourceType: "Bundle",
    type: "transaction",
    entry: [
      {
        fullUrl: `https://example.com/base/DiagnosticReport/${diagnosticReport.id}`,
        resource: diagnosticReport,
        request: {
          method: "POST",
          url: `DiagnosticReport/${diagnosticReport.id}`,
        },
      },
      ...primaryPainObservations.map((observation) => ({
        fullUrl: `https://example.com/base/Observation/${observation.id}`,
        resource: observation,
        request: {
          method: "POST",
          url: `Observation/${observation.id}`,
        },
      })),
      ...additionalPainObservations.map((observation) => ({
        fullUrl: `https://example.com/base/Observation/${observation.id}`,
        resource: observation,
        request: {
          method: "POST",
          url: `Observation/${observation.id}`,
        },
      })),
    ],
  };

  return bundle;
}
