export class Marker {
  constructor(draw, initialLocation) {
    this.draw = draw;
    this.marker = null;
    this.initialLocation = initialLocation;
  }

  setup() {
    this.marker = this.createMarker(this.draw);
    this.move(this.initialLocation.x, this.initialLocation.y);
  }

  move(x, y) {
    this.marker.move(x - 11, y - 11);
  }

  createMarker() {
    const crossRadius = 10;
    const crossOutlineMultiplier = 1.1;

    // Group to hold all parts of the marker
    const marker = this.draw.group();

    // Draw the outline
    marker
      .line(
        0,
        -crossRadius * crossOutlineMultiplier,
        0,
        crossRadius * crossOutlineMultiplier
      )
      .stroke({ width: 7, color: "black" });
    marker
      .line(
        -crossRadius * crossOutlineMultiplier,
        0,
        crossRadius * crossOutlineMultiplier,
        0
      )
      .stroke({ width: 7, color: "black" });

    // Draw the main cross
    marker
      .line(0, -crossRadius, 0, crossRadius)
      .stroke({ width: 5, color: "#d23f31" });
    marker
      .line(-crossRadius, 0, crossRadius, 0)
      .stroke({ width: 5, color: "#d23f31" });
    return marker;
  }

  getMarkerPosition() {
    return {
      x: this.marker.cx(),
      y: this.marker.cy(),
    };
  }

  getMarkerBoundingBox() {
    return this.marker.bbox();
  }

  reset() {
    this.marker.remove();
  }
}
